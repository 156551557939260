import React from "react";

const Profile = () => {
  return (
    <div className="" style={{ height: "100vh" }}>
      {" "}
      <h1 className="text-center  ">Profile</h1>
    </div>
  );
};

export default Profile;
